<template>
  <div v-if="havePockets">
    <ul class="list-unstyled mb-0">
      <!-- for each entry we need one of these -->
        <li v-for="pocket in pockets" :key="pocket.id" :class="$style.item" >
          <div :class="$style.itemPic">
            <i>{{ pocket.name.charAt(0) }}</i>
          </div>
          <div class="mr-2">
            <div>
              <strong class="text-dark">{{ pocket.name }}</strong>
            </div>
            <div class="text-muted">Owned by you</div>
          </div>
          <div class="ml-auto text-right text-nowrap">
            <div class="text-success">{{ numberWithCommasAndCurrencyCode(pocket.balance, pocket.currency_code)  }}</div>
  <!--          <div class="text-success">+187.89$</div>-->
          </div>
        </li>
      <!-- end entry -->
    </ul>
  </div>
  <div v-else>
    <p v-if="ownedByUser">You dont have any pockets - create one below</p>
    <p v-else>Noone has shared any pockets with you.</p>
  </div>
</template>
<script>

import currencyMixins from '@/utils/mixins/currencyMixins'

export default {
  name: 'AppPocketBalanceList',
  mixins: [currencyMixins],
  props: {
    pockets: {
      type: Array,
      default: () => [],
    },
    ownedByUser: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    havePockets() {
      if (this.pockets && this.pockets.length > 0) {
        return true
      }
      return false
    } ,
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
