<template>
  <div class="d-flex flex-wrap align-items-center">
    <div class="mr-auto">
      <p class="text-uppercase text-dark font-weight-bold mb-1">
        {{ title }}
      </p>
      <p class="text-gray-5 mb-0">
        {{ subTitle }}
      </p>
    </div>
    <p :class='this.getAmountClassBasedOnSign + " font-weight-bold font-size-24 mb-0"'>
<!--    <p class="text-success font-weight-bold font-size-24 mb-0">-->
      {{ numberWithCommasAndCurrencyCode(amount, currencyCode) }}
    </p>
  </div>
</template>
<script>

// mixin files
import currencyMixins from '@/utils/mixins/currencyMixins'

export default {
  name: 'AppDashboardAmountWidget',
  props: {
    title: String,
    subTitle: String,
    amount: Number,
    currencyCode: String,
    sign: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ['POSITIVE', 'NEGATIVE', 'BALANCE'].indexOf(value) !== -1
      },
    },
  },
  mixins: [currencyMixins],
  computed: {
    getAmountClassBasedOnSign() {
      // return  'text-success'
      if (this.sign == 'POSITIVE') {
        return 'text-success'
      } else if (this.sign == 'NEGATIVE') {
        return 'text-danger'
      } else if (this.sign == 'BALANCE') {
        return 'text-primary'
      }
      return 'text-warning'
    },
  },
  // computed: {
  //   // a computed getter
  //   amountWithCurrency: function () {
  //     return this.message.split('').reverse().join('')
  //   },
  // },
}
</script>
