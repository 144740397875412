<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-header">
            <vb-headers-heading-2
              :data="{
                title: 'Tieno Transactions Summary',
                description:
                  'Everything here relates to your accounts at Tieno',

                }"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-body">
            <app-dashboard-amount-widget title="Balance"
                                         subTitle="Balance in Account & Pockets"
                                         :amount=this.myMoneyAccountBalance
                                         :currencyCode=this.myMoneyAccountCurrencyCode
                                         sign="BALANCE"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-body">
            <app-dashboard-amount-widget title = "Money In"
                                         subTitle="Payments to You and Deposits"
                                         :amount=this.myMoneyAccountInForever
                                         :currencyCode=this.myMoneyAccountCurrencyCode
                                         sign="POSITIVE"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-body">
            <app-dashboard-amount-widget title="Money Out"
                                         subTitle="Payments by you or Pay outs"
                                         :amount=this.myMoneyAccountOutForever
                                         :currencyCode=this.myMoneyAccountCurrencyCode
                                         sign="NEGATIVE"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Recent Transactions' }" />
          </div>
          <div class="card-body"><vb-tables-antd-2 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Your Pocket Balances' }" />
          </div>
<!--          <div class="card-header py-0">-->
<!--            <vb-headers-card-header-2 :data="{ title: 'Your Pocket Balances' }" />-->
<!--          </div>-->
          <div class="card-body">
            <app-pocket-balance-list :pockets=this.myPockets :owned-by-user=true />
          </div>
          <div class="card-footer align:right">
            <div>
              <a class="btn-primary btn mr-2" v-on:click="showCreateNewPocketForConsumer">Create a new Pocket</a>
            </div>
          </div>
        </div>
        <div class="card card-top card-top-primary">
<!--          <div class="card-header py-0">-->
<!--            <vb-headers-card-header-2 :data="{ title: 'Delegated Pocket Balances' }" />-->
<!--          </div>-->
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Delegated Pocket Balances' }" />
          </div>
          <div class="card-body">
            <app-pocket-balance-list :pockets=this.delegatedPockets
                                     :owned-by-user=false
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row"><div class="col-lg-12"></div></div>
  </div>
</template>

<script>
import AppDashboardAmountWidget from "@/components/Theme/Widgets/DashboardWidgets/DashboardAmountWidget";
import VbHeadersCardHeader from '@/@vb/widgets/Headers/CardHeader'
import VbTablesAntd2 from '@/@vb/widgets/TablesAntd/2'
// import VbHeadersCardHeader2 from '@/@vb/widgets/Headers/CardHeader2'
// import VbWidgetsLists6 from '@/@vb/widgets/WidgetsLists/6'
import AppPocketBalanceList from '@/components/Consumer/MoneyAccounts/PocketBalanceList'
// import VbHeadersCardFooter2 from '@/@vb/widgets/Headers/CardFooter2'
import VbHeadersHeading2 from '@/@vb/widgets/Headers/Heading2'
import {useStore} from "vuex";
import {computed} from "vue";
import { useRouter } from 'vue-router'
// data load

export default {
  name: 'VbHome',
  components: {
    AppDashboardAmountWidget,
    VbHeadersHeading2,
    // VbWidgetsGeneral2,
    // VbWidgetsGeneral2v2,
    VbHeadersCardHeader,
    VbTablesAntd2,
    // VbHeadersCardHeader2,
    AppPocketBalanceList,
    // VbHeadersCardFooter2,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    // get the account-summary
    store.dispatch('consumer/fetchMyAccountSummary')

    const myMoneyAccountBalance = computed(() => store.state.consumer.myMoneyAccountSummary['balance'])
    const myMoneyAccountCurrencyCode = computed(() => store.state.consumer.myMoneyAccountSummary['currency_code'])
    const myMoneyAccountOutForever = computed(() => store.state.consumer.myMoneyAccountSummary['money_in_forever_amount'])
    const myMoneyAccountInForever = computed(() => store.state.consumer.myMoneyAccountSummary['money_out_forever_amount'])

    const myPockets = computed(() => store.state.consumer.myMoneyAccountSummary['owned_pockets'])
    const delegatedPockets = computed(() => store.state.consumer.myMoneyAccountSummary['delegated_pockets'])

    function showCreateNewPocketForConsumer() {
      console.log('Loading a new page to craete the pocket')
      router.push('/consumer/pockets/create')
    }

    // const myMoneyAccountBalance = computed(() => 10000)

    return {
      myMoneyAccountBalance,
      myMoneyAccountCurrencyCode,
      myMoneyAccountOutForever,
      myMoneyAccountInForever,
      myPockets,
      delegatedPockets,
      showCreateNewPocketForConsumer,
    }
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
  },
  // computed: {
  //   myMoneyAccountBalance() {
  //     console.error('Display the MYAccountSummary')
  //     console.error(store.state.consumer.myMoneyAccountSummary)
  //     // return 10000.00
  //     return store.state.consumer.myMoneyAccountSummary['balance']
  //   },
  //   myMoneyAccountCurrency() {
  //     return this.$store.state.consumer.myMoneyAccountSummary.currency_code
  //   },
  // },
  created() {
  },
}
</script>
